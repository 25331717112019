import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5380f63c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "record-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Search, { onSearchList: _ctx.refreshList }, null, 8, ["onSearchList"]),
    _createVNode(_component_el_table, {
      data: _ctx.tableData,
      stripe: "",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "ID",
          label: "发送ID"
        }),
        _createVNode(_component_el_table_column, {
          prop: "CreatedAt",
          label: "发送时间"
        }),
        _createVNode(_component_el_table_column, {
          prop: "CreatedAt",
          label: "发送状态"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.recordObj[scope.row.Status]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "Content",
          label: "内容"
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_pagination, {
      class: "page",
      onCurrentChange: _ctx.onChangePage,
      "current-page": _ctx.page,
      "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
      "page-size": _ctx.pageSize,
      layout: "prev, pager, next, jumper",
      total: _ctx.total
    }, null, 8, ["onCurrentChange", "current-page", "page-size", "total"])
  ]))
}