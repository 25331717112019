
import { RECORD_LIST } from '@/config/constEnum'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'Search',
  emits: ['searchList'],
  setup(props, { emit }) {
    const phone = ref('')
    const status = ref('')

    const search = () => {
      phone.value = phone.value ? phone.value : 'all'
      status.value = status.value ? status.value : 'all'
      emit('searchList', {
        phone: phone.value,
        status: status.value
      })
    }

    return {
      phone,
      status,
      search,
      recordList: RECORD_LIST
    }
  }
})
