
import { defineComponent, onMounted, ref } from 'vue'
import Search from './components/Search.vue'
import axios from 'axios'
import { RECORD_OBJ } from '@/config/constEnum'

export default defineComponent({
  name: 'Record',
  components: { Search },
  setup() {
    const tableData = ref([])
    const total = ref(0)
    const pageSize = 10
    const page = ref(1)

    const status = ref('all')
    const phone = ref('all')

    const refreshList = (searchPhone: { phone: string; status: string }) => {
      phone.value = searchPhone.phone
      status.value = searchPhone.status
      getList()
    }

    const onChangePage = (val: any) => {
      page.value = val
      getList()
    }

    const getList = () => {
      const reqData = {
        status: status.value,
        phone: phone.value,
        size: pageSize,
        page: page.value
      }
      axios.get('/-/user/stat/history', { params: reqData }).then(res => {
        const data = res.data.msg
        total.value = data.total
        tableData.value = data.smss
      })
    }

    onMounted(() => {
      getList()
    })
    return {
      page,
      total,
      phone,
      status,
      getList,
      pageSize,
      tableData,
      refreshList,
      onChangePage,
      recordObj: RECORD_OBJ
    }
  }
})
